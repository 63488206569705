import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';

import { BorrowerId } from '@willow/types-iso';

export const useGetImpersonatedUserId = (): BorrowerId | undefined => {
  const [queryParams] = useQueryParams({
    userId: StringParam,
    adminImpersonate: BooleanParam,
  });

  return queryParams.adminImpersonate && queryParams.userId ? (queryParams.userId as BorrowerId) : undefined;
};
