/*
  NOTE: the documents page has two different component displays for desktop vs mobile.
  If there is a change to the display somewhere, be sure to update both the table and accordion components
*/
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
  GetDocumentsForLoanDocument,
  GetDocumentUrlDocument,
  GetPdfStatementUrlDocument,
  PortalPaymentDueFragment,
} from '@willow/graphql-iso/src/portal';
import { getCdnUrlForFile } from '@willow/shared-iso';
import { DocumentDisplayItem, NamedMemo, Pagination, Sentry } from '@willow/shared-web';
import { DocumentId, LoanId, PaymentDueId } from '@willow/types-iso';

import { useGetImpersonatedUserId } from '../../hooks/useGetImpersonatedUserId';
import { TDocumentTypeOption } from '../../pages/documents/form';
import { getTableItems } from './utils/formatDocuments';
import { ViewDocumentsAccordion } from './ViewDocumentsAccordion/ViewDocumentsAccordion';
import { ViewDocumentsTable } from './ViewDocumentsTable/ViewDocumentsTable';

import './ViewDocuments.scss';

export interface Props {
  documentType: TDocumentTypeOption;
  loanId: LoanId;
  paymentsDue: PortalPaymentDueFragment[];
}

export const ViewDocuments = NamedMemo<Props>('ViewDocuments', ({ documentType, loanId, paymentsDue }) => {
  const { data } = useQuery(GetDocumentsForLoanDocument, {
    variables: {
      loanId,
      userId: useGetImpersonatedUserId(),
    },
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [getPdfStatementUrl] = useMutation(GetPdfStatementUrlDocument);
  const [getDocumentUrl] = useMutation(GetDocumentUrlDocument);

  // Combine + sort the documents to display in the table
  const displayDocuments = useMemo(() => {
    return getTableItems(documentType, paymentsDue, data?.portalSelf.loan.currentSnapshot.documents ?? []);
  }, [documentType, paymentsDue, data]);

  const paginatedDocuments = useMemo(() => {
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;

    return displayDocuments.slice(startIndex, endIndex);
  }, [displayDocuments, currentPage, pageSize]);

  const downloadStatement = useCallback(
    async (paymentDueId: PaymentDueId, statementVersion: number) => {
      // Get aws download link for the statement clicked
      try {
        const response = await getPdfStatementUrl({
          variables: { loanId, paymentDueId, version: statementVersion },
          fetchPolicy: 'no-cache',
        });

        if (!response.data?.getPdfStatementUrl?.url) {
          Sentry.captureException(
            `Attempted to download statement for paymentDueId: ${paymentDueId}, version ${statementVersion}`,
          );
          toast.error('Something went wrong downloading the statement, please try again.');
          return;
        }

        window.open(response.data.getPdfStatementUrl.url, '_self');
      } catch (err) {
        Sentry.captureException(err);
        toast.error('Something went wrong downloading the statement, please try again.');
      }
    },
    [loanId, getPdfStatementUrl],
  );

  const downloadDocument = useCallback(
    async (documentId: DocumentId) => {
      // Get aws download link for the document clicked
      try {
        const response = await getDocumentUrl({
          variables: { loanId, documentId },
          fetchPolicy: 'no-cache',
        });

        if (!response.data?.getDocumentUrl?.url) {
          Sentry.captureException(`Attempted to download documentId: ${documentId}`);
          toast.error('Something went wrong downloading the document, please try again.');
          return;
        }

        window.open(response.data.getDocumentUrl.url, '_self');
      } catch (err) {
        Sentry.captureException(err);
        toast.error('Something went wrong downloading the document, please try again.');
      }
    },
    [loanId, getDocumentUrl],
  );

  const onDocumentDownloadClick = useCallback(
    async (document: DocumentDisplayItem) => {
      switch (document.documentType) {
        case 'Document':
          downloadDocument(document.id);
          break;
        case 'Statement':
          downloadStatement(document.id, document.version);
          break;
        default:
          toast.error('Something went wrong downloading the document, please try again.');
          Sentry.captureException(
            `There was a problem downloading a document for loanId: ${loanId}, loanId: ${loanId}, documentType not accounted for}`,
          );
      }
    },
    [loanId, downloadStatement, downloadDocument],
  );

  return (
    <section className="view-documents">
      {displayDocuments?.length === 0 ? (
        // NO DOCUMENTS TO DISPLAY
        <div className="view-documents__empty" data-testid="view-documents-empty">
          <div className="view-documents__empty__icon" aria-hidden="true">
            <img src={getCdnUrlForFile('statement')} alt="Statement" />
          </div>
          <div className="view-documents__empty__content">
            <h2>Statement available soon</h2>
            <h3>You will be notified when your statement is ready</h3>
          </div>
        </div>
      ) : (
        // PAGINATED LIST OF DOCUMENTS
        <>
          <ViewDocumentsTable
            documents={paginatedDocuments}
            handleDocumentDownload={(document) => onDocumentDownloadClick(document)}
          />
          <ViewDocumentsAccordion
            documents={paginatedDocuments}
            handleDocumentDownload={(document) => onDocumentDownloadClick(document)}
          />
          <Pagination
            pageIndex={currentPage}
            totalResults={displayDocuments?.length}
            pageSize={pageSize}
            gotoPage={setCurrentPage}
            setPageSize={setPageSize}
          />
        </>
      )}
    </section>
  );
});
