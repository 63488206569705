import { z } from 'zod';

import { BorrowerId, DocumentId, PaymentId } from '../BrandedIds';
import { EmailAttachment } from '../EmailAttachment';
import {
  BaseEmailTemplateType,
  BaseEmailWithAttachmentTemplateType,
  DocumentEmailTemplateType,
  GoodbyeBeforeWelcome,
  GoodbyeEmail,
  PaymentEmailTemplateType,
  UpdateAutopayEmailTemplateType,
} from '../emails/Templates';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const SendEmailOptions = z
  .object({
    disableEmail: z.boolean().optional(),
    disableDocument: z.boolean().optional(),
    disableAuditLog: z.boolean().optional(),
  })
  .optional();

const SendBaseEmailEvent = BaseLoanEvent.extend({
  type: z.literal('sendEmail'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  template: BaseEmailTemplateType,
  opts: SendEmailOptions,
});
type SendBaseEmailEvent = z.infer<typeof SendBaseEmailEvent>;

const SendGoodbyeEmailEvent = SendBaseEmailEvent.extend({
  template: z.union([GoodbyeEmail, GoodbyeBeforeWelcome]),
  isUpdate: z.boolean().optional(),
});
type SendGoodbyeEmailEvent = z.infer<typeof SendGoodbyeEmailEvent>;

const SendEmailWithAttachmentEvent = SendBaseEmailEvent.extend({
  template: BaseEmailWithAttachmentTemplateType,
  attachments: z.array(EmailAttachment),
});
type SendEmailWithAttachmentEvent = z.infer<typeof SendEmailWithAttachmentEvent>;

const SendPaymentEmailEvent = SendBaseEmailEvent.extend({
  template: PaymentEmailTemplateType,
  paymentId: zodBrandedUuid<PaymentId>(),
});
type SendPaymentEmailEvent = z.infer<typeof SendPaymentEmailEvent>;

const SendDwollaUpdateAutopayEmailEvent = SendBaseEmailEvent.extend({
  paymentProvider: z.literal('dwolla'),
  template: UpdateAutopayEmailTemplateType,
  autopayBankUrl: z.string(),
});
const SendUsioUpdateAutopayEmailEvent = SendBaseEmailEvent.extend({
  paymentProvider: z.literal('usio'),
  template: UpdateAutopayEmailTemplateType,
  confirmationId: z.string(),
});
const SendUpdateAutopayEmailEvent = z.union([SendDwollaUpdateAutopayEmailEvent, SendUsioUpdateAutopayEmailEvent]);
type SendUpdateAutopayEmailEvent = z.infer<typeof SendUpdateAutopayEmailEvent>;

const SendDocumentEmailEvent = SendBaseEmailEvent.extend({
  template: DocumentEmailTemplateType,
  documentId: zodBrandedUuid<DocumentId>(),
});
type SendDocumentEmailEvent = z.infer<typeof SendDocumentEmailEvent>;

export const SendEmailEvent = z.union([
  SendBaseEmailEvent,
  SendGoodbyeEmailEvent,
  SendEmailWithAttachmentEvent,
  SendPaymentEmailEvent,
  SendUpdateAutopayEmailEvent,
  SendDocumentEmailEvent,
]);
export type SendEmailEvent = z.infer<typeof SendEmailEvent>;
