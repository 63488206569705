import classnames from 'classnames';
import { reverse } from 'lodash';

import { LoanStatus } from '@willow/graphql-iso/src/portal';
import { getNextPaymentDueDate, NamedMemo, paymentDateFormat } from '@willow/shared-web';
import { Accordion } from '@willow/shared-web/bootstrap';

import { PortalSelectedLoan } from '../../App';
import { EmptyTransactions } from '../../components/empty-transactions/EmptyTransactions';
import { Metadata } from '../../components/metadata/Metadata';
import { PageHeading } from '../../components/page-heading/PageHeading';
import { isTransferredView } from '../../utils/loanView';

import './Transactions.scss';

interface Props {
  loan: PortalSelectedLoan;
}

export const TransactionsPage = NamedMemo<Props>('TransactionsPage', ({ loan }) => {
  const { losId, currentSnapshot } = loan;
  const { paymentsDue, loanStatus, transactionActivities } = currentSnapshot;

  const hasTransactions = transactionActivities ? transactionActivities.length > 0 : false;

  const nextPaymentDue = getNextPaymentDueDate(reverse([...paymentsDue]));

  // Determine which view is applicable if no payments have been made on the loan
  const isTransferLoan = isTransferredView(loan);
  const isPreActiveLoan = [LoanStatus.PendingActivation, LoanStatus.PreActive].includes(loanStatus) && !isTransferLoan;

  // Set page copy based on loan state
  let pageHeadingSubtitle;
  if (!hasTransactions) {
    pageHeadingSubtitle = isTransferLoan ? 'No online transactions recorded' : 'No transactions have been recorded';
  }

  return (
    <div className="page-transactions">
      <Metadata metadata={{ title: 'Transactions' }} />

      <PageHeading title="Transactions" subtitle={pageHeadingSubtitle} />

      {hasTransactions ? (
        <>
          <section className="transactions__wrapper">
            {/* DESKTOP VERSION: TABLE */}
            <div className="transactions__table">
              <table>
                <tbody>
                  <tr className="transactions__table__head">
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Status</th>
                  </tr>

                  {transactionActivities.map((transactionActivity, index) => {
                    const { date, amount, subAmount, type, subType, status, isFailed } = transactionActivity;

                    return (
                      <tr key={`transactions-desktop-${index}`}>
                        <td>{paymentDateFormat(date, true)}</td>
                        <td data-ghost={`portal-transaction--amount${index}`}>
                          {amount}
                          {subAmount && <span>{subAmount}</span>}
                        </td>
                        <td data-ghost={`portal-transaction--method${index}`}>
                          {type}
                          {subType && <span>{subType}</span>}
                        </td>
                        <td className={classnames({ transactions__accordion__error: isFailed })}>{status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* TABLET/MOBILE VERSION: ACCORDION */}
            <div className="transactions__accordion">
              <Accordion defaultActiveKey="0">
                {transactionActivities.map((transactionActivity, index) => {
                  const { date, amount, type, subType, status, isFailed } = transactionActivity;

                  return (
                    <Accordion.Item key={`transactions-mobile-${index}`} eventKey={index.toString()}>
                      <Accordion.Header>
                        <div className="transactions__accordion__wrapper">
                          <div className="transactions__accordion__date">{paymentDateFormat(date, true)}</div>
                          {isFailed ? (
                            <div className="transactions__accordion__error">Failed</div>
                          ) : (
                            <div className="transactions__accordion__amount">{amount}</div>
                          )}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="transactions__accordion__wrapper">
                          <div className="transactions__accordion__headline">Type</div>
                          <div className="transactions__accordion__description">
                            {type}
                            {subType && <span>{subType}</span>}
                          </div>
                        </div>

                        <div className="transactions__accordion__wrapper">
                          {isFailed ? (
                            <>
                              <div className="transactions__accordion__headline">Amount</div>
                              <div className="transactions__accordion__description">{amount}</div>
                            </>
                          ) : (
                            <>
                              <div className="transactions__accordion__headline">Status</div>
                              <div className="transactions__accordion__description">{status}</div>
                            </>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </section>
        </>
      ) : (
        <EmptyTransactions
          isTransferLoan={isTransferLoan}
          isPreActiveLoan={isPreActiveLoan}
          nextPaymentDue={nextPaymentDue}
          losId={losId}
          companyId={loan.company.id}
        />
      )}
    </div>
  );
});
