import { z } from 'zod';

import { LoanTransferData } from '../loan/LoanTransfer';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateTransferDataEvent = BaseLoanEvent.extend({
  type: z.literal('updateTransferData'),
  transferData: LoanTransferData,
});
export type UpdateTransferDataEvent = z.infer<typeof UpdateTransferDataEvent>;
