import { z } from 'zod';

import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GenerateLoanTransferDocumentsSideEffectContext = BaseLoanIdSideEffect.extend({
  isUpdate: z.boolean().optional(),
});

export const GenerateLoanTransferDocumentsSideEffect = GenerateLoanTransferDocumentsSideEffectContext.extend({
  type: z.literal('generateLoanTransferDocuments'),
});
export type GenerateLoanTransferDocumentsSideEffect = z.infer<typeof GenerateLoanTransferDocumentsSideEffect>;
