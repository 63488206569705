import * as z from 'zod';

import { zodDateOrString } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateLoanBoardedDatesEvent = BaseLoanEvent.extend({
  type: z.literal('updateLoanBoardedDates'),
  fundingDate: zodDateOrString,
  firstPaymentDate: zodDateOrString, // first payment due date on loan + in Willow
  maturityDate: zodDateOrString,
});
export type UpdateLoanBoardedDatesEvent = z.infer<typeof UpdateLoanBoardedDatesEvent>;
