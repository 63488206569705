import { z } from 'zod';

import { Payment } from '../loan/Payments';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateDepositEvent = BaseLoanEvent.extend({
  type: z.literal('createDeposit'),
  deposit: Payment,
});
export type CreateDepositEvent = z.infer<typeof CreateDepositEvent>;
