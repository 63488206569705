import { z } from 'zod';

import { LosId } from '../BrandedIds';
import {
  AutopayEmailData,
  BorrowerEmailData,
  BorrowerEmailHtmlData,
  DefermentCancelledEmailData,
  DelinquentInterestEmailData,
  EscrowAnalysisAvailableEmailData,
  FinalPaymentDueEmailData,
  Form1098AvailableEmailData,
  GoodbyeBeforeWelcomeEmailData,
  GoodbyeEmailData,
  PaidInFullStatementEmailData,
  PaymentDueAutopayEmailData,
  PaymentDueEmailData,
  PaymentEmailData,
  PaymentPostedEmailData,
  PaymentReminderAutopayEmailData,
  PaymentReminderEmailData,
  RescindGoodbyeEmailData,
  WelcomeEmailData,
  WelcomePreActiveEmailData,
} from './BorrowerEmailContext';
import { ExternalPaymentDueEmailData } from './constants/externalPaymentDue';
import { ExternalPayoffQuoteEmailData } from './constants/externalPayoffQuote';
import { MortgageeLetterEmail, MortgageeLetterEmailData } from './constants/mortgageeLetter';

/**
 * Steps when adding a new email mjml template:
 * 1) add a const literal for the new template name (borrower/lender group)
 * 2) add this const literal to EmailTemplateType
 * 3) also add to the appropriate type groups
 *    - usually BaseEmailTemplateType if email uses SendBaseEmailEvent/SendBaseEmailSideEffectContext
 *    (Send...EmailEvent and Send...EmailSideEffectContext should match)
 * 4) update EmailMapping interface - match this literal with its EmailData
 *
 * 5) getEmailContext.ts: add literal case to match with appropriate context in EmailArgsMapping
 *    - then update: sideEffects/sendEmail.ts for borrower email side effect or handleSendEmail.ts for borrower email event
 * 6) EmailHtmlService.ts: add subject and header message for literal case
 * 7) CompanyLoanResolvers.ts: add emailName case
 *
 * 8) shared-node/scripts/sendEmail.ts: update development script to test
 */

/// step 1
/* Borrower Emails */
export const GoodbyeEmail = z.literal('goodbye');
export const GoodbyeBeforeWelcome = z.literal('goodbyeBeforeWelcome');
const LatePaymentEmail = z.literal('latePayment');
export const GracePastDueEmail = z.literal('gracePastDue');
const NextStatementEmail = z.literal('nextStatementAvailable');
const NextStatementInterestReserveEmail = z.literal('nextStatementAvailableInterestReserve');
const NextStatementAutopayEmail = z.literal('nextStatementAvailableAutopay');
const BorrowerRevisedStatement = z.literal('borrowerRevisedStatement');
export const PaymentCancelledEmail = z.literal('paymentCancelled');
export const PaymentFailedEmail = z.literal('paymentFailed');
export const PaymentFailedAutopayEmail = z.literal('paymentFailedAutopay');
const PaymentSubmittedEmail = z.literal('paymentSubmitted');
const PaymentPostedEmail = z.literal('paymentPosted');
const PaymentPostedInterestReserveEmail = z.literal('paymentPostedInterestReserve');
const PaymentReminderEmail = z.literal('paymentReminder');
const PaymentPastDueReminderEmail = z.literal('paymentPastDueReminder');
const PaymentReminderAutopayEmail = z.literal('paymentReminderAutopay');
export const RescindGoodbyeEmail = z.literal('rescindGoodbye');
export const WelcomeEmail = z.literal('welcome');
export const WelcomePreActiveEmail = z.literal('welcomePreActive');
export const WelcomeInterestReserveEmail = z.literal('welcomeInterestReserve');
export const WelcomeAutoPayEmail = z.literal('welcomeAutoPay');
const AutopayEnabledEmail = z.literal('autopayEnabled');
const AutopayDisabledEmail = z.literal('autopayDisabled');
const BorrowerSuspendedEmail = z.literal('borrowerSuspended');
const BorrowerAccountCreatedEmail = z.literal('borrowerAccountCreated');
const BorrowerAccountVerifiedEmail = z.literal('borrowerAccountVerified');
const BorrowerAccountRemovedEmail = z.literal('borrowerAccountRemoved');
const BorrowerUserVerifiedEmail = z.literal('borrowerUserVerified');
const BorrowerDocumentationRequiredEmail = z.literal('borrowerDocumentationRequired');
const BorrowerDocumentationAddedEmail = z.literal('borrowerDocumentationAdded');
const BorrowerDocumentationRejectedEmail = z.literal('borrowerDocumentationRejected');
const BorrowerDocumentationApprovedEmail = z.literal('borrowerDocumentationApproved');
const BorrowerMicroDepositPostedEmail = z.literal('borrowerMicroDepositPosted');
const BorrowerErrorEmail = z.literal('borrowerError');
const PayoffQuote = z.literal('payoffQuote');
const NewDocumentAvailable = z.literal('newDocumentAvailable');
const PaidInFullStatementEmail = z.literal('paidInFullStatement');
const Form1098AvailableEmail = z.literal('form1098Available');
const EscrowAnalysisAvailableEmail = z.literal('escrowAnalysisAvailable');
const BorrowerDelinquentInterestAppliedEmail = z.literal('borrowerDelinquentInterestApplied');
export const FinalPaymentEmail = z.literal('finalPayment');
const PortalInstructionsEmail = z.literal('portalInstructions');

/* Lender Emails */
const LenderWelcomeEmail = z.literal('lenderWelcome');
const LenderAccountSuspendedEmail = z.literal('lenderAccountSuspended');
const LenderDocumentationRequiredEmail = z.literal('lenderDocumentationRequired');
const LenderDocumentationAddedEmail = z.literal('lenderDocumentationAdded');
const LenderDocumentationApprovedEmail = z.literal('lenderDocumentationApproved');
const LenderDocumentationRejectedEmail = z.literal('lenderDocumentationRejected');
const LenderPaymentCancelledEmail = z.literal('lenderPaymentCancelled');
const LenderPaymentFailedEmail = z.literal('lenderPaymentFailed');
const LenderAccountVerifiedEmail = z.literal('lenderAccountVerified');
const LenderAccountRemovedEmail = z.literal('lenderAccountRemoved');
const LenderUserVerifiedEmail = z.literal('lenderUserVerified');
const LenderPaymentPostedEmail = z.literal('lenderPaymentPosted');
const LenderPaymentSubmittedEmail = z.literal('lenderPaymentSubmitted');
const LenderDownloadReportEmail = z.literal('lenderDownloadReport');
const LenderSidePaymentFailedEmail = z.literal('lenderSidePaymentFailed');
const LenderMicroDepositPostedEmail = z.literal('lenderMicroDepositPosted');
const LenderSweepCompleteEmail = z.literal('lenderSweepComplete');
const LenderSweepFailedEmail = z.literal('lenderSweepFailed');
const LenderGeneratePayoffReminderEmail = z.literal('lenderGeneratePayoffReminder');
const LenderForm1098sDraftedEmail = z.literal('lenderForm1098sDrafted');
const DefermentCancelledEmail = z.literal('defermentCancelled');

/* External Emails */
export const ExternalPayoffQuoteEmail = z.literal('externalPayoffQuote');
export const ExternalPaymentDueEmail = z.literal('externalPaymentDue');

/// step 2
/* Types */
export const EmailTemplateType = z.union([
  LatePaymentEmail,
  GracePastDueEmail,
  NextStatementEmail,
  NextStatementInterestReserveEmail,
  NextStatementAutopayEmail,
  FinalPaymentEmail,
  BorrowerRevisedStatement,
  PaymentReminderEmail,
  PaymentPastDueReminderEmail,
  PaymentReminderAutopayEmail,
  WelcomeEmail,
  WelcomePreActiveEmail,
  WelcomeInterestReserveEmail,
  WelcomeAutoPayEmail,
  AutopayEnabledEmail,
  AutopayDisabledEmail,
  BorrowerSuspendedEmail,
  BorrowerAccountCreatedEmail,
  BorrowerAccountVerifiedEmail,
  BorrowerAccountRemovedEmail,
  BorrowerUserVerifiedEmail,
  BorrowerDocumentationRequiredEmail,
  BorrowerDocumentationAddedEmail,
  BorrowerDocumentationRejectedEmail,
  BorrowerDocumentationApprovedEmail,
  BorrowerMicroDepositPostedEmail,
  BorrowerErrorEmail,
  BorrowerDelinquentInterestAppliedEmail,
  PaidInFullStatementEmail,
  NewDocumentAvailable,
  Form1098AvailableEmail,
  EscrowAnalysisAvailableEmail,
  DefermentCancelledEmail,
  PortalInstructionsEmail,

  // Lender emails
  LenderAccountSuspendedEmail,
  LenderDocumentationRequiredEmail,
  LenderDocumentationAddedEmail,
  LenderDocumentationApprovedEmail,
  LenderDocumentationRejectedEmail,
  LenderAccountVerifiedEmail,
  LenderAccountRemovedEmail,
  LenderUserVerifiedEmail,
  LenderMicroDepositPostedEmail,
  LenderSweepCompleteEmail,
  LenderSweepFailedEmail,
  LenderGeneratePayoffReminderEmail,
  LenderForm1098sDraftedEmail,
  LenderPaymentCancelledEmail,
  LenderPaymentFailedEmail,
  LenderPaymentPostedEmail,
  LenderPaymentSubmittedEmail,
  LenderSidePaymentFailedEmail,
  LenderDownloadReportEmail, // report context
  LenderWelcomeEmail, // lender welcome context

  // attachment context
  PayoffQuote,
  // payment context
  PaymentCancelledEmail,
  PaymentFailedEmail,
  PaymentFailedAutopayEmail,
  PaymentPostedEmail,
  PaymentPostedInterestReserveEmail,
  PaymentSubmittedEmail,

  // transfer context
  GoodbyeEmail,
  GoodbyeBeforeWelcome,
  RescindGoodbyeEmail,

  // External Emails
  MortgageeLetterEmail,
  ExternalPayoffQuoteEmail,
  ExternalPaymentDueEmail,
]);
export type EmailTemplateType = z.infer<typeof EmailTemplateType>;

/// step 3
export const BaseEmailTemplateType = z.union([
  BorrowerErrorEmail,
  LatePaymentEmail,
  GracePastDueEmail,
  NextStatementEmail,
  NextStatementInterestReserveEmail,
  NextStatementAutopayEmail,
  FinalPaymentEmail,
  BorrowerRevisedStatement,
  PaymentReminderEmail,
  PaymentPastDueReminderEmail,
  PaymentReminderAutopayEmail,
  WelcomeEmail,
  WelcomePreActiveEmail,
  WelcomeInterestReserveEmail,
  WelcomeAutoPayEmail,
  PaymentFailedAutopayEmail,
  NewDocumentAvailable,
  PaidInFullStatementEmail,
  DefermentCancelledEmail,
  BorrowerDelinquentInterestAppliedEmail,
  PortalInstructionsEmail,
]);

export const BaseEmailWithAttachmentTemplateType = PayoffQuote; // Make union when we have more than one email template
export type BaseEmailWithAttachmentTemplateType = z.infer<typeof BaseEmailWithAttachmentTemplateType>;

// One-off templates that use their own mjml file or are sent to external parties
export const ExternalEmailTemplateType = z.union([
  MortgageeLetterEmail,
  ExternalPayoffQuoteEmail,
  ExternalPaymentDueEmail,
]);
export type ExternalEmailTemplateType = z.infer<typeof ExternalEmailTemplateType>;

export const NextStatementEmailTemplateType = z.union([
  NextStatementEmail,
  NextStatementInterestReserveEmail,
  NextStatementAutopayEmail,
]);
export type NextStatementEmailTemplateType = z.infer<typeof NextStatementEmailTemplateType>;

export const GeneratePayoffReminderEmailType = LenderGeneratePayoffReminderEmail;
export type GeneratePayoffReminderEmailType = z.infer<typeof GeneratePayoffReminderEmailType>;

// payment emails need a specific paymentId
export const PaymentEmailTemplateType = z.union([
  PaymentCancelledEmail,
  PaymentFailedEmail,
  PaymentPostedEmail,
  PaymentSubmittedEmail,
  PaymentPostedInterestReserveEmail,
]);
export type PaymentEmailTemplateType = z.infer<typeof PaymentEmailTemplateType>;
export const PaymentLenderEmailTemplateType = z.union([
  LenderPaymentCancelledEmail,
  LenderPaymentFailedEmail,
  LenderPaymentPostedEmail,
  LenderPaymentSubmittedEmail,
  LenderSidePaymentFailedEmail,
  LenderSweepCompleteEmail,
  LenderSweepFailedEmail,
]);
export type PaymentLenderEmailTemplateType = z.infer<typeof PaymentLenderEmailTemplateType>;

export const UpdateAutopayEmailTemplateType = z.union([AutopayEnabledEmail, AutopayDisabledEmail]);
export type UpdateAutopayEmailTemplateType = z.infer<typeof UpdateAutopayEmailTemplateType>;

export const DocumentEmailTemplateType = z.union([Form1098AvailableEmail, EscrowAnalysisAvailableEmail]);
export type DocumentEmailTemplateType = z.infer<typeof DocumentEmailTemplateType>;

// keeping track of lender email type choices for build.ts that use baseTemplateLender
export const LenderEmailTemplateType = z.union([
  LenderWelcomeEmail,
  LenderAccountSuspendedEmail,
  LenderDocumentationRequiredEmail,
  LenderDocumentationAddedEmail,
  LenderDocumentationApprovedEmail,
  LenderDocumentationRejectedEmail,
  LenderAccountVerifiedEmail,
  LenderAccountRemovedEmail,
  LenderUserVerifiedEmail,
  LenderDownloadReportEmail,
  LenderMicroDepositPostedEmail,
  GeneratePayoffReminderEmailType,
  LenderForm1098sDraftedEmail,
  ...PaymentLenderEmailTemplateType.options,
]);
export type LenderEmailTemplateType = z.infer<typeof LenderEmailTemplateType>;

// track all of the lender emails that are toggable in the notifications setting page
export const LenderToggableEmails = z.union([
  LenderPaymentSubmittedEmail,
  LenderPaymentPostedEmail,
  LenderPaymentFailedEmail,
  LenderPaymentCancelledEmail,
  LenderSweepCompleteEmail,
  LenderSweepFailedEmail,
  LenderAccountVerifiedEmail,
  LenderAccountRemovedEmail,
]);
export type LenderToggableEmails = z.infer<typeof LenderToggableEmails>;

// BorrowerEmailTemplateType is the inverse of LenderEmailTemplateTypes
// If we end up introducing a new role outside of Borrower and Lender that gets its own set of emails,
// Then we need to define BorrowerEmailTemplateType explicitly like we've done with other template types.
export const NonBorrowerTemplateType = z.union([LenderEmailTemplateType, ExternalEmailTemplateType]);
export type NonBorrowerTemplateType = z.infer<typeof NonBorrowerTemplateType>;
export type BorrowerEmailTemplateType = Exclude<EmailTemplateType, NonBorrowerTemplateType>;

// keeping track of dwolla specific emails that dont have loan context and are directly sent without event/sideEffect
export const DwollaUserEmailTemplateType = z.union([
  BorrowerSuspendedEmail,
  BorrowerAccountCreatedEmail,
  BorrowerAccountVerifiedEmail,
  BorrowerAccountRemovedEmail,
  BorrowerUserVerifiedEmail,
  BorrowerDocumentationRequiredEmail,
  BorrowerDocumentationAddedEmail,
  BorrowerDocumentationRejectedEmail,
  BorrowerDocumentationApprovedEmail,
  BorrowerMicroDepositPostedEmail,
]);
export type DwollaUserEmailTemplateType = z.infer<typeof DwollaUserEmailTemplateType>;
export const DwollaCompanyEmailTemplateType = z.union([
  LenderAccountSuspendedEmail,
  LenderAccountVerifiedEmail,
  LenderAccountRemovedEmail,
  LenderDocumentationAddedEmail,
  LenderDocumentationRejectedEmail,
  LenderDocumentationRequiredEmail,
  LenderDocumentationApprovedEmail,
  LenderUserVerifiedEmail,
  LenderMicroDepositPostedEmail,
]);
export type DwollaCompanyEmailTemplateType = z.infer<typeof DwollaCompanyEmailTemplateType>;

// These are the emails that a company is allowed to view
export const ViewableEmailTemplateType = z.union([
  WelcomePreActiveEmail,
  WelcomeEmail,
  WelcomeInterestReserveEmail,
  WelcomeAutoPayEmail,
  NextStatementEmail,
  NextStatementAutopayEmail,
  FinalPaymentEmail,
  BorrowerRevisedStatement,
  PaymentReminderEmail,
  PaymentReminderAutopayEmail,
  GracePastDueEmail,
  PaymentSubmittedEmail,
  PaymentPostedEmail,
  PaymentFailedEmail,
  PaymentFailedAutopayEmail,
  PaymentCancelledEmail,
  LatePaymentEmail,
  GoodbyeEmail,
  GoodbyeBeforeWelcome,
  RescindGoodbyeEmail,
  BorrowerDelinquentInterestAppliedEmail,
]);
export type ViewableEmailTemplateType = z.infer<typeof ViewableEmailTemplateType>;

// WARNING: DO NOT REMOVE FIELDS from the customizable email types and be careful what you edit.
// Saved versions of the email template may still reference the data you're editing.
// Instead of removing or renaming fields, just add new ones.
export const CustomizableEmailTemplateType = z.union([
  WelcomeEmail,
  WelcomePreActiveEmail,
  GoodbyeEmail,
  GoodbyeBeforeWelcome,
  RescindGoodbyeEmail,
  NextStatementEmail,
  NextStatementAutopayEmail,
  PaymentReminderEmail,
  PaymentReminderAutopayEmail,
  PaymentSubmittedEmail,
  PaymentPostedEmail,
  PaymentFailedEmail,
  PaymentFailedAutopayEmail,
  LatePaymentEmail,
]);
export type CustomizableEmailTemplateType = z.infer<typeof CustomizableEmailTemplateType>;

export interface LenderSideEffectEmailMapping {
  lenderPaymentCancelled: LenderDwollaBankEmailData;
  lenderPaymentFailed: LenderDwollaBankEmailData;
  lenderPaymentPosted: LenderDwollaBankEmailData;
  lenderPaymentSubmitted: LenderDwollaBankEmailData;
  lenderSidePaymentFailed: LenderDwollaBankEmailData;
  lenderSweepComplete: LenderSweepEmailData;
  lenderSweepFailed: LenderSweepEmailData;
  lenderGeneratePayoffReminder: LenderPayoffReminderEmailData;
}
/// step 4
export interface EmailMapping extends LenderSideEffectEmailMapping {
  portalInstructions: BorrowerEmailData;
  goodbyeBeforeWelcome: GoodbyeBeforeWelcomeEmailData;
  goodbye: GoodbyeEmailData;
  latePayment: PaymentDueEmailData;
  gracePastDue: PaymentDueEmailData;
  nextStatementAvailable: PaymentDueEmailData;
  nextStatementAvailableInterestReserve: PaymentDueEmailData;
  nextStatementAvailableAutopay: PaymentDueAutopayEmailData;
  finalPayment: FinalPaymentDueEmailData;
  borrowerRevisedStatement: PaymentDueEmailData;
  paymentCancelled: PaymentEmailData;
  paymentFailed: PaymentEmailData;
  paymentFailedAutopay: PaymentDueAutopayEmailData;
  paymentSubmitted: PaymentEmailData;
  paymentPosted: PaymentPostedEmailData;
  paymentPostedInterestReserve: PaymentPostedEmailData;
  paymentReminder: PaymentReminderEmailData;
  paymentPastDueReminder: PaymentReminderEmailData;
  paymentReminderAutopay: PaymentReminderAutopayEmailData;
  rescindGoodbye: RescindGoodbyeEmailData;
  welcome: WelcomeEmailData;
  welcomePreActive: WelcomePreActiveEmailData;
  welcomeInterestReserve: WelcomeEmailData;
  welcomeAutoPay: WelcomeEmailData;
  autopayEnabled: AutopayEmailData;
  autopayDisabled: AutopayEmailData;
  borrowerAccountCreated: BorrowerEmailData;
  borrowerAccountVerified: BorrowerDwollaAccountData;
  borrowerAccountRemoved: BorrowerDwollaAccountData;
  borrowerUserVerified: BorrowerEmailData;
  borrowerSuspended: BorrowerEmailData;
  borrowerDocumentationRequired: BorrowerEmailData;
  borrowerDocumentationAdded: BorrowerEmailData;
  borrowerDocumentationRejected: BorrowerEmailData;
  borrowerDocumentationApproved: BorrowerEmailData;
  borrowerMicroDepositPosted: BorrowerEmailData;
  borrowerError: BorrowerEmailData;
  borrowerDelinquentInterestApplied: DelinquentInterestEmailData;
  newDocumentAvailable: BorrowerEmailData;
  lenderWelcome: LenderWelcomeEmailData;
  lenderAccountSuspended: NoEmailData;
  lenderDocumentationRequired: BaseLenderDwollaEmailData;
  lenderDocumentationAdded: BaseLenderDwollaEmailData;
  lenderDocumentationApproved: BaseLenderDwollaEmailData;
  lenderDocumentationRejected: BaseLenderDwollaEmailData;
  lenderAccountVerified: DwollaAccountData;
  lenderAccountRemoved: DwollaAccountData;
  lenderUserVerified: BaseLenderEmailData;
  lenderDownloadReport: LenderDownloadEmailData;
  lenderMicroDepositPosted: BaseLenderEmailData;
  lenderForm1098sDrafted: LenderQcDocsDraftedArgs;
  payoffQuote: BorrowerEmailData;
  paidInFullStatement: PaidInFullStatementEmailData;
  form1098Available: Form1098AvailableEmailData;
  escrowAnalysisAvailable: EscrowAnalysisAvailableEmailData;
  defermentCancelled: DefermentCancelledEmailData;

  /* External emails */
  mortgageeLetter: MortgageeLetterEmailData;
  externalPayoffQuote: ExternalPayoffQuoteEmailData;
  externalPaymentDue: ExternalPaymentDueEmailData;
}

// keeping track of email data interfaces by sideEffects group (BorrowerEmailHtmlData for sendEmail and LenderEmailHtmlData for sendLenderEmail)
export type LenderEmailHtmlData =
  | LenderDwollaBankEmailData
  | LenderDownloadEmailData
  | LenderWelcomeEmailData
  | LenderPayoffReminderEmailData
  | LenderQcDocsDraftedArgs;
export type LenderSideEffectEmailHtmlData =
  | LenderDwollaBankEmailData
  | LenderPayoffReminderEmailData
  | LenderSweepEmailData;
export type ExternalEmailHtmlData = MortgageeLetterEmailData;
export type EmailHtmlData =
  | BorrowerEmailHtmlData
  | LenderEmailHtmlData
  | DwollaAccountData
  | ExternalEmailHtmlData
  | NoEmailData;

interface NoEmailData {}

interface BaseLenderEmailData {
  appUrl: string;
}

interface BaseLenderDwollaEmailData {
  appUrl?: string; // Some lender emails have button links
}

export interface DwollaAccountData extends BaseLenderDwollaEmailData {
  date: string;
  bankName: string;
  bankAccountIdentifier: string;
}
export interface BorrowerDwollaAccountData extends Omit<BorrowerEmailData, 'losId'>, DwollaAccountData {}

export interface LenderWelcomeEmailData extends BaseLenderDwollaEmailData {
  passwordUrl: string;
}

export interface LenderDwollaBankEmailData extends BaseLenderDwollaEmailData {
  losId: LosId;
  maskedLosId: string;
  paymentDate: string;
  paymentMethod: string;
  paymentAmount: string;
  amountDueRemaining: string;
  bankAccountIdentifiers: string[];
  borrowerName: string;
}

export interface LenderPayoffReminderEmailData extends BaseLenderEmailData {
  losId: LosId;
  maturityDate: string;
}

export interface LenderSweepEmailData extends BaseLenderDwollaEmailData {
  paymentAmount: string;
  sourceBankAccountIdentifier: string;
  destinationBankAccountIdentifier: string;
}

export interface LenderQcDocsDraftedArgs extends BaseLenderEmailData {
  createdCount: number;
  qcCount: number;
  skippedCount: number;
  failedCount: number;
}

interface LenderDownloadEmailData {
  downloadUrl: string;
}

// Customizable template settings are used by Companies
// This is where we'd eventually customize the subject and pre-header
export const EmailTemplateSettings = z.object({
  partials: z.object({
    base: z.string().nonempty(),
    main: z.string().nonempty(),
    footer: z.string().nonempty(),
  }),
  subject: z.string().nonempty(),
  header: z.string().nonempty(),
  generated: z.string().nonempty(),
});
export type EmailTemplateSettings = z.infer<typeof EmailTemplateSettings>;

export type MjmlParseError = {
  line: number;
  message: string;
};
