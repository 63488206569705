import { z } from 'zod';

import { FeeId, PaymentConfigId, PaymentDueId, WorkoutPlanId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';
import { PositiveMonetaryValue } from '../validations/fields';

export const WorkoutPlanBase = z.object({
  id: zodBrandedUuid<WorkoutPlanId>(),
  status: z.enum(['active', 'cancelled', 'completed']),
  startDate: zodDateOrString,
  endDate: zodDateOrString,

  principalAmount: PositiveMonetaryValue,
  interestAmount: PositiveMonetaryValue,
  feeAmount: PositiveMonetaryValue,
  escrowAmount: PositiveMonetaryValue,
  totalAmount: PositiveMonetaryValue,

  hasPrincipalAndInterest: z.boolean(),
  hasFees: z.boolean(),
  hasEscrow: z.boolean(),

  paymentDueIds: z.array(zodBrandedUuid<PaymentDueId>()),
  chargeIds: z.array(zodBrandedUuid<FeeId>()),

  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,

  paymentConfigId: zodBrandedUuid<PaymentConfigId>().optional(),
});

export const DefermentWorkoutPlan = WorkoutPlanBase.extend({
  type: z.literal('paymentDeferment'),
  reason: z.enum(['naturalDisaster']).optional(),
});

export type DefermentWorkoutPlan = z.infer<typeof DefermentWorkoutPlan>;

export const RepaymentWorkoutPlan = WorkoutPlanBase.extend({
  type: z.literal('repaymentPlan'),
  paymentsProcessed: z.number().int().nonnegative().optional(),
  numberOfPayments: z.number().int().positive(),
});

export type RepaymentWorkoutPlan = z.infer<typeof RepaymentWorkoutPlan>;

export const WorkoutPlan = z.discriminatedUnion('type', [DefermentWorkoutPlan, RepaymentWorkoutPlan]);

export type WorkoutPlan = z.infer<typeof WorkoutPlan>;

export const WorkoutPlanType = z.enum(['paymentDeferment', 'repaymentPlan']);

export type WorkoutPlanType = z.infer<typeof WorkoutPlanType>;

export const isDefermentWorkoutPlan = (workoutPlan: WorkoutPlan): workoutPlan is DefermentWorkoutPlan =>
  workoutPlan.type === 'paymentDeferment';

export const isRepaymentWorkoutPlan = (workoutPlan: WorkoutPlan): workoutPlan is RepaymentWorkoutPlan =>
  workoutPlan.type === 'repaymentPlan';

export const DefermentWorkoutPlanInput = DefermentWorkoutPlan.omit({
  id: true,
  status: true,
  createdAt: true,
  updatedAt: true,
  paymentDueIds: true,
  chargeIds: true,
  principalAmount: true,
  interestAmount: true,
  feeAmount: true,
  escrowAmount: true,
  totalAmount: true,
});

export type DefermentWorkoutPlanInput = z.infer<typeof DefermentWorkoutPlanInput>;

export const RepaymentWorkoutPlanInput = RepaymentWorkoutPlan.omit({
  id: true,
  status: true,
  createdAt: true,
  updatedAt: true,
  paymentDueIds: true,
  chargeIds: true,
  principalAmount: true,
  interestAmount: true,
  feeAmount: true,
  escrowAmount: true,
  totalAmount: true,
});

export type RepaymentWorkoutPlanInput = z.infer<typeof RepaymentWorkoutPlanInput>;

export const WorkoutPlanInput = z.discriminatedUnion('type', [DefermentWorkoutPlanInput, RepaymentWorkoutPlanInput]);

export type WorkoutPlanInput = z.infer<typeof WorkoutPlanInput>;
