import { PortalSelfQuery } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';

import './ViewingAs.scss';

export const ViewingAs = NamedMemo<{ portalUser?: PortalSelfQuery['portalSelf'] }>('ViewingAs', ({ portalUser }) => {
  return portalUser ? (
    <>
      <p className="viewing-as-container u-fs-4 fw-bold pb-0">
        Viewing as {portalUser.firstName} {portalUser.lastName}
      </p>
      <p className="viewing-as-container mb-4 u-fs-2 pt-0">
        Note: you will not see the impersonated user's bank accounts{' '}
      </p>
    </>
  ) : (
    <p className="viewing-as-container">No user data retrieved</p>
  );
});
