/*
  View displayed when there are no payments or other transactions recorded on a loan
*/

import { NavLink } from 'react-router-dom';

import { getCdnUrlForFile } from '@willow/shared-iso';
import { NamedMemo, paymentDateFormat, PORTAL_LOAN_PATHS } from '@willow/shared-web';
import { CompanyId, LosId } from '@willow/types-iso';

import './EmptyTransactions.scss';

interface Props {
  isTransferLoan: Boolean;
  isPreActiveLoan: Boolean;
  nextPaymentDue: string | undefined;
  losId: LosId;
  companyId: CompanyId;
}

export const EmptyTransactions = NamedMemo<Props>(
  'EmptyTransactions',
  ({ isTransferLoan, isPreActiveLoan, nextPaymentDue, losId, companyId }) => {
    const defaultView = !isTransferLoan && !isPreActiveLoan;

    // Determine which icon to display
    const icon = isPreActiveLoan ? (
      <img src={getCdnUrlForFile('statement')} alt="Statement" />
    ) : (
      <img src={getCdnUrlForFile('transactions')} alt="Transactions" />
    );

    return (
      <section className="empty-transactions">
        <div className="empty-transactions__icon" aria-hidden="true">
          {icon}
        </div>
        <div className="empty-transactions__content">
          {isTransferLoan && (
            <>
              <h2>Your loan has been transferred</h2>
              <h3>No payments are expected</h3>
            </>
          )}

          {isPreActiveLoan && (
            <>
              <h2>No transactions have been recorded</h2>
              <h3>Payments and disbursements can be found here</h3>
            </>
          )}

          {defaultView && (
            <>
              <h2>Payment due {paymentDateFormat(nextPaymentDue, true)}</h2>
              <NavLink to={PORTAL_LOAN_PATHS.root({ losId, companyId })} exact className="empty-transactions__btn">
                Make a Payment
              </NavLink>
            </>
          )}
        </div>
      </section>
    );
  },
);
