import classNames from 'classnames';
import { compact } from 'lodash';
import { ReactComponentElement } from 'react';
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { NamedMemo } from '@willow/shared-web';
import { Accordion } from '@willow/shared-web/bootstrap';

import { NavSectionItem } from './NavSectionItem';

import s from './NavSection.module.scss';

export interface Props {
  children: (ReactComponentElement<typeof NavSectionItem> | null | false | undefined)[];
}

export const NavSection = NamedMemo<Props>('NavSection', ({ children }) => {
  const { url } = useRouteMatch();
  const location = useLocation();

  const TABS = compact(children).map((child) => ({ key: child.key, label: child.props.label, component: child }));

  return (
    <>
      <section className={classNames('d-md-none mt-4', s.accordion)}>
        <Accordion defaultActiveKey={location.pathname}>
          {TABS.map((tab) => (
            <Accordion.Item key={tab.key} eventKey={`${url}/${tab.key}`}>
              <Accordion.Header as={NavLink} to={`${url}/${tab.key}`} className="u-bg-maple3" exact={false}>
                {tab.label}
              </Accordion.Header>
              <Accordion.Body>{tab.component}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </section>

      <section className="u-bg-white u-border-1 u-border-color-bark1 mt-4 d-none d-md-flex">
        <nav className={`u-border-r-1 u-border-color-bark1 p-3 ${s.nav} `}>
          {TABS.map((tab) => (
            <NavLink
              key={tab.key}
              to={`${url}/${tab.key}`}
              exact={false}
              className={(isActive) =>
                classNames(
                  'd-block u-fs-2 u-border-1 u-border-r-3 p-2 mb-1 hover:u-color-primary hover:u-bg-secondary',
                  {
                    'u-color-primary u-bold': isActive,
                    'u-color-bark3 u-border-color-transparent': !isActive,
                  },
                )
              }
            >
              {tab.label}
            </NavLink>
          ))}
        </nav>

        <div className="p-4 container">
          <Switch>
            {TABS.map((tab) => (
              <Route key={tab.key} path={`${url}/${tab.key}`}>
                {tab.component}
              </Route>
            ))}
            <Route path="*" render={() => <Redirect to={`${url}/${TABS[0].key}`} />} />
          </Switch>
        </div>
      </section>
    </>
  );
});
